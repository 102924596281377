<template>
  <div class="expande-horizontal column pt-0">
    <v-flex xs12>
      <div class="windows-style expande-horizontal fonte column">
        <v-list-item class="pa-0 ma-0">
          <v-avatar
            @click="backToList"
            style="cursor: pointer;"
            size="27"
            :color="$theme.primary"
            class="mr-2"
            icon
          >
            <v-icon color="#f2f2f2">mdi-close</v-icon>
          </v-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              {{ get_lead.new ? "Novo lead" : get_lead.name }}
            </v-list-item-title>
            <v-list-item-subtitle class="font-mini">
              Gerencie os detalhes abaixo
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-flex>
    <div
      style="min-height: 50vh;"
      class="windows-style-content expande-horizontal wrap"
    >
      <v-tabs
        v-model="tabs"
        :background-color="$theme.primary"
        dark
        class="fonte"
      >
        <v-tab>Info do lead</v-tab>
        <!-- <v-tab @click="listClientSales">Compras do lead</v-tab> -->
      </v-tabs>
      <v-flex v-if="tabs == 0" xs12>
        <v-flex xs12 class="py-3">
          <span
            style="font-size: 16pt;"
            class="pa-2 mb-3 fonte fonte-bold"
            v-if="get_lead.planType"
            >Com plano: {{ get_lead.planType }}</span
          >
        </v-flex>
        <v-form ref="form">
          <v-flex class="px-2" xs12>
            <v-select
              v-model="get_lead.current_status"
              label="Status"
              :items="['aguardando', 'convertido', 'não-convertido', 'falso-positivo']"
              :color="$theme.primary"
              :rules="[v => !!v || 'Preencha este campo']"
            ></v-select>
          </v-flex>

          <v-flex class="px-2" xs12>
            <v-text-field
              v-model="get_lead.name"
              filled
              label="Nome"
              clearable
              :color="$theme.primary"
              :rules="[v => !!v || 'Preencha este campo']"
              placeholder="ex: Roberto"
            ></v-text-field>
          </v-flex>

          <v-flex class="px-2" xs12>
            <v-text-field
              v-model="get_lead.email"
              clearable
              filled
              :color="$theme.primary"
              label="Email"
              placeholder="ex: meuemail@gmail.com"
            ></v-text-field>
          </v-flex>

          <div class="expande-horizontal wrap">
            <v-flex class="px-2" xs12 md4>
              <v-text-field
                v-model="get_lead.enterprise"
                label="Empresa"
                filled
                clearable
                :color="$theme.primary"
              ></v-text-field>
            </v-flex>
            <v-flex class="px-2" xs12 md4>
              <v-text-field
                v-model="get_lead.whatsapp"
                label="Celular"
                v-mask="['(##) # #### ####']"
                clearable
                filled
                :color="$theme.primary"
                placeholder="ex: (96) 9 8419-6827"
              ></v-text-field>
            </v-flex>
          </div>
          <div class="expande-horizontal">
            <v-flex class="px-2" xs12>
              <v-textarea
                v-model="get_lead.message"
                label="Mensagem"
                filled
                clearable
                :color="$theme.primary"
              ></v-textarea>
            </v-flex>
          </div>
        </v-form>
      </v-flex>
      <!-- Compras do lead -->
      <v-flex v-if="tabs == 1" xs12>
        <div class="expande-horizontal wrap">
          <h2 class="fonte-bold ml-3">
            O lead realizou {{ sales.length }} compras.
          </h2>
          <div
            style="min-height: 67vh; max-height: 67vh; overflow: auto;"
            class="expande-horizontal px-3"
            v-if="sales.length > 0"
          >
            <v-flex xs12 md3 class="mr-3" v-for="sale in sales" :key="sale._id">
              <SaleItem :kanban="sale" />
            </v-flex>
          </div>

          <div class="expande-horizontal centraliza" v-else>
            <span class="my-12 fonte"
              >Este lead ainda não realizou nenhuma compra</span
            >
          </div>
        </div>
      </v-flex>
    </div>

    <div class="expande-horizontal px-2 py-1 windows-style-bottom">
      <v-btn large dark @click="valida_form" :color="$theme.primary">
        <span class="fonte white--text fonte-bold"> Salvar </span>
      </v-btn>
      <!-- <v-btn
        v-if="get_lead._id"
        color="red"
        class="ml-3"
        @click="excluir_lead_dialog(get_lead)"
        text
        >Excluir</v-btn
      > -->
    </div>
  </div>
</template>

<script>
import SaleItem from "@/apps/GestorDePedido/components/SaleItem.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    SaleItem
  },
  computed: {
    ...mapGetters(["get_lead", "getLoggedUser"])
  },
  data() {
    return {
      tabs: 0,
      sales: []
    };
  },
  methods: {
    ...mapActions([
      "criar_lead",
      "atualizar_lead",
      "fecha_modal_view_lead",
      "enableLoading",
      "disableLoading"
    ]),
    listClientSales() {
      this.enableLoading();
      this.$run("leads/list-client-sales", { _id: this.get_lead._id }).then(
        res => {
          this.disableLoading();
          this.sales = res.data;
        }
      );
    },
    valida_form() {
      if (this.$refs.form.validate()) {
        this.get_lead._id ? this.atualizar_lead() : this.criar_lead();
      }
    },
    backToList() {
      this.$store.commit("set_lead", {});
      this.$forceUpdate();
    }
  }
};
</script>
